// ==============================
//* SLIDER
//
//? This file gathers all the page designer sliders scripts.
// ==============================

import lazyLoad from "./components/lazyLoad.js";

/*
Use in component json
"attribute_definition_groups": [
    {
        "id": "extraSmallConfiguration",
        "name": "Phone configurations",
        "description": "Configuration for how the carousel will display on phones. Please put like sized items in the carousel",
        "attribute_definitions": [
            {
            "id": "xsCarouselIndicators",
            "name": "Display carousel indicators on phones",
            "type": "boolean",
            "required": false
            },
            {
            "id": "xsCarouselControls",
            "name": "Display carousel controls on phones",
            "type": "boolean",
            "required": false
            },
            {
            "id": "xsCarouselSlidesToDisplay",
            "name": "Number of slides to display on phones",
            "type": "enum",
            "values": [1, 2],
            "required": true
            }
        ]
    },
    {
        "id": "smallConfiguration",
        "name": "Tablet configurations",
        "description": "Configuration for how the carousel will display on tablets. Please put like sized items in the carousel",
        "attribute_definitions": [
            {
            "id": "smCarouselIndicators",
            "name": "Display carousel indicators on tablets",
            "type": "boolean",
            "required": false
            },
            {
            "id": "smCarouselControls",
            "name": "Display carousel controls on tablets",
            "type": "boolean",
            "required": false
            },
            {
            "id": "smCarouselSlidesToDisplay",
            "name": "Number of slides to display on tablets",
            "type": "enum",
            "values": [1, 2, 3, 4],
            "required": true
            }
        ]
    },
    {
        "id": "mediumConfiguration",
        "name": "Desktop configurations",
        "description": "Configuration for how the carousel will display on desktop. Please put like sized items in the carousel",
        "attribute_definitions": [
            {
            "id": "mdCarouselIndicators",
            "name": "Display carousel indicators on desktop",
            "type": "boolean",
            "required": false
            },
            {
            "id": "mdCarouselSlidesToDisplay",
            "name": "Number of slides to display on desktop",
            "type": "enum",
            "values": [3, 4],
            "required": true
            }
        ]
    }
]
*/

/**
 * @description Init slider
 */
function initSlider() {
    const sectionsSliders = ".section-slider";

    for (let i = 0; i < $(sectionsSliders).length; i++) {
        const sectionSlider = $(sectionsSliders)[i];
        const sliderWrapper = $(sectionSlider).find(".slider-wrapper");
        const slider = $(sectionSlider).hasClass("list-slider") ? $(sectionSlider).find("ul") : $(sectionSlider).find(".slider");
        const sliderChildren = $(sectionSlider).hasClass("list-slider") ? $(slider).find("> li") : $(slider).find("> div");
        const xsCarouselSlidesToDisplay = typeof $(sliderWrapper).data("xs") !== "undefined" ? $(sliderWrapper).data("xs") : 1;
        const smCarouselSlidesToDisplay = typeof $(sliderWrapper).data("sm") !== "undefined" ? $(sliderWrapper).data("sm") : 1;
        const mdCarouselSlidesToDisplay = typeof $(sliderWrapper).data("md") !== "undefined" ? $(sliderWrapper).data("md") : 1;
        const xsCarouselSlidesToScroll = typeof $(sliderWrapper).data("scroll-xs") !== "undefined" ? $(sliderWrapper).data("scroll-xs") : 1;
        const smCarouselSlidesToScroll = typeof $(sliderWrapper).data("scroll-sm") !== "undefined" ? $(sliderWrapper).data("scroll-sm") : 1;
        const mdCarouselSlidesToScroll = typeof $(sliderWrapper).data("scroll-md") !== "undefined" ? $(sliderWrapper).data("scroll-md") : 1;
        const xsCarouselIndicators = typeof $(sliderWrapper).data("indicators-xs") !== "undefined" ? $(sliderWrapper).data("indicators-xs") : false;
        const smCarouselIndicators = typeof $(sliderWrapper).data("indicators-sm") !== "undefined" ? $(sliderWrapper).data("indicators-sm") : false;
        const xsCarouselControls = typeof $(sliderWrapper).data("controls-xs") !== "undefined" ? $(sliderWrapper).data("controls-xs") : false;
        const smCarouselControls = typeof $(sliderWrapper).data("controls-sm") !== "undefined" ? $(sliderWrapper).data("controls-sm") : false;
        const displayArrows = typeof $(sliderWrapper).data("display-arrows") !== "undefined" ? $(sliderWrapper).data("display-arrows") : true;
        const displayIndicators = typeof $(sliderWrapper).data("display-indicators") !== "undefined" ? $(sliderWrapper).data("display-indicators") : false;
        const autoplaySpeed = typeof $(sliderWrapper).data("autoplay-speed") !== "undefined" ? $(sliderWrapper).data("autoplay-speed") : 5000;
        const autoplay = $(sliderWrapper).data("autoplay-speed") ? true : false;
        const variableWidth = typeof $(sliderWrapper).data("variable-width") !== "undefined" ? $(sliderWrapper).data("variable-width") : false;
        const centerMode = typeof $(sliderWrapper).data("center-mode") !== "undefined" ? $(sliderWrapper).data("center-mode") : false;

        if (sliderChildren.length > 1) {
            // Init slider
            $(slider).slick({
                infinite: true,
                cssEase: "linear",
                slidesToShow: mdCarouselSlidesToDisplay,
                slidesToScroll: mdCarouselSlidesToScroll,
                arrows: displayArrows,
                dots: displayIndicators,
                autoplay: autoplay,
                autoplaySpeed: autoplaySpeed,
                pauseOnHover: true,
                variableWidth: variableWidth,
                centerMode: centerMode,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            infinite: true,
                            cssEase: "linear",
                            slidesToShow: smCarouselSlidesToDisplay,
                            slidesToScroll: smCarouselSlidesToScroll,
                            arrows: smCarouselControls,
                            dots: smCarouselIndicators
                        }
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            infinite: true,
                            cssEase: "linear",
                            slidesToShow: xsCarouselSlidesToDisplay,
                            slidesToScroll: xsCarouselSlidesToScroll,
                            arrows: xsCarouselControls,
                            dots: xsCarouselIndicators
                        }
                    }
                ]
            });

            // Set disabled class on prev button on page load
            const slickPrev = $(slider).find(".slick-prev");
            const slickNext = $(slider).find(".slick-next");

            if (slickPrev.length > 0) $(slickPrev).addClass("disabled");
            $(slickNext).on("click", () => {
                $(slickPrev).removeClass("disabled");
            });

            // Set slider pagination
            if (sliderChildren.length >= mdCarouselSlidesToDisplay) {
                const dots = $(slider).find(".slick-dots li");
                const dotsBtn = $(dots).find("button");
                $(dotsBtn).append(" / " + dots.length);
            } else {
                $(slider).find(".slick-dots").hide();
            }
        }
    }
} initSlider();

/**
 * @description Wait for You may also like exits
 *
 * @returns {elements} exist
 */
function checkAlsoLikeExist() {
    return $(".also-like-products").length >= 1;
}

/**
 * @description Wait for You may also like exits
 *
 * @returns {elements} exist
 */
function checkPerfectWithExist() {
    return $(".perfect-with-products").length >= 1;
}

/**
 * @description Init Slider
 *
 */
function handleAlsoLikeExistence() {
    clearInterval(waitAlsoLikeInterval);
    initSlider();
    lazyLoad.init();
}
/**
 * @description Init Slider
 *
 */
function handlePerfectWithExistence() {
    clearInterval(waitPerfectWithInterval);
    initSlider();
    lazyLoad.init();
}

var waitAlsoLikeInterval = setInterval(function () {
    try {
        if (checkAlsoLikeExist()) {
            handleAlsoLikeExistence();
        }
    } catch (error) {
        console.log("Error occurred during waiting:", error);
    }
}, 100);

var waitPerfectWithInterval = setInterval(function () {
    try {
        if (checkPerfectWithExist()) {
            handlePerfectWithExistence();
        }
    } catch (error) {
        console.log("Error occurred during waiting:", error);
    }
}, 100);
